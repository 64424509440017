
import { postApiReq, getApiReq, getReq, postReq } from "@utils/ApiHandler";

import Cookies from 'js-cookie';
import { select, put, takeLatest, all } from 'redux-saga/effects';










function* actionWatcher() {
  yield takeLatest('SET_WALLET_ID', getUserDetails);
  yield takeLatest('GET_USER_DETAILS', getUserDetails);
  yield takeLatest('GET_TOP_CREATORS', getTopCreators);
  yield takeLatest('SET_USER_TOKEN', getUserDetails);
  yield takeLatest('GET_USER_COLLECTION', getUserCollection);
  yield takeLatest('SET_SELECTED_COLLECTION_ID', getCollectionDetails);
  yield takeLatest('SET_SELECTED_COLLECTION_DATA', getCollectionNFT);
  yield takeLatest('SET_SELECTED_NFT_ID', getNftDetails);
  yield takeLatest('SET_SELECTED_RENT_NFT_ID', getRentNftDetails);
  yield takeLatest('SET_SELECTED_NFT_DATA', getRelatedNft);
  yield takeLatest('SET_CREATOR_DETAILS', setCreatorDetails);
  yield takeLatest('GET_CREATOR_DETAILS', getCreatorCollection);
  yield takeLatest('DISPATCH_SELL_ORDER', createSellOrder);
  yield takeLatest('GET_EXPLORE_CREATOR', getExplorCreator);
  yield takeLatest('SET_ARTIST_OFFSET', getExplorCreator);
  yield takeLatest('SET_ARTIST_SEARCH', getExplorCreator);
  yield takeLatest('GET_ALL_NFT', getNFtData);
  yield takeLatest('SET_NFT_SEARCH', getNFtData);
  yield takeLatest('SET_NFT_OFFSET', getNFtData);
  yield takeLatest('SET_NFT_FILTER', getNFtData);

  yield takeLatest('SET_COLLECTION_NFT_OFFSET', getCollectionNFT);
  yield takeLatest('SET_COLLECTION_NFT_FILTER', getCollectionNFT);
}


export default function* rootSaga() {
  yield all([
    actionWatcher(),
  ]);
}






function* getTopCreators() {
  const response = yield getReq(`/creators?rows=12&sort=POPLOUR`)
  try {
    if (response.status) {
      yield put({
        type: "SET_TOP_CREATORS", payload: response.data
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "SET_TOP_CREATORS", payload: null });
  }
}
function* getUserDetails() {
  if (Boolean(Cookies.get("user-data"))) {
    const response = yield getApiReq('/user/get-user-details')
    try {
      if (response.status) {
        yield put({
          type: "SET_USER_DETAILS", payload: response.data
        })
      }
      else {
        yield put({
          type: "SHOW_TOAST", payload: ({ type: 'error', message: response.error })
        });
      }

    } catch (e) {
      yield put({ type: "SET_USER_DETAILS", payload: null });
    }
  }
}
function* getUserCollection() {
  const { user: user, offset } = yield select(state => state.userDetails)
  const response = yield getApiReq(`/collections/?creatorId=${user?._id}&offset=${offset}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_USER_COLLECTION", payload: response.data
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "SET_USER_COLLECTION", payload: null });
  }
}

function* getCollectionDetails() {
  const { collectionId: id } = yield select(state => state.collectionReducer)
  const { user } = yield select(state => state.userDetails)

  const response = yield getReq(`/collection/${id}?userId=${user?._id}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_SELECTED_COLLECTION_DATA", payload: response.data
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "SET_SELECTED_COLLECTION_DATA", payload: null });
  }
}

function* getCollectionNFT() {

  yield put({
    type: "SET_IS_LOADING", payload: true
  })

  const { collectionData, filter, offset } = yield select(state => state.collectionReducer)
  const { user } = yield select(state => state.userDetails)

  var str = ``
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      if (value instanceof Array) {
        value.map((item, index) => {
          if (item)
            str = str + `&filter[${key}][${index}]=${item}`
        })
      }
      else {
        for (var price in value) {
          if (value[price])
            str = str + `&filter[${key}][${price}]=${value[price]}`
        }
      }

    }
  }


  const response = yield getApiReq(`/arts/?collectionId=${collectionData?._id}&offset=${offset}&userId=${user?._id}${str && str}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_SELECTED_COLLECTION_NFT", payload: response.data
      })
      yield put({
        type: "SET_IS_LOADING", payload: false
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
      yield put({
        type: "SET_IS_LOADING", payload: false
      })
    }
  } catch (e) {
    yield put({ type: "SET_SELECTED_COLLECTION_NFT", payload: [] });
    yield put({
      type: "SET_IS_LOADING", payload: false
    })
  }
}
function* getNftDetails() {
  const { nftId: id } = yield select(state => state.nftReducer)
  const { user } = yield select(state => state.userDetails)
  const response = yield getReq(`/art/${id}?userId=${user?._id}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_SELECTED_NFT_DATA", payload: response.data
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "SET_SELECTED_NFT_DATA", payload: null });
  }
}

function* getRentNftDetails() {
  const { nftId: id } = yield select(state => state.nftReducer)
  const { user } = yield select(state => state.userDetails)
  const response = yield getReq(`/rent-nft/${id}?userId=${user?._id}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_SELECTED_NFT_DATA", payload: response.data
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "SET_SELECTED_NFT_DATA", payload: null });
  }
}


function* getRelatedNft() {
  const { nftId: id } = yield select(state => state.nftReducer)

  return
  const response = yield getApiReq(`/user/art/${id}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_SELECTED_NFT_DATA", payload: response.data
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "SET_SELECTED_NFT_DATA", payload: null });
  }
}

function* setCreatorDetails() {
  yield put({ type: "SET_IS_LOADING", payload: true })
  const { user } = yield select(state => state.userDetails);
  const { creatorId } = yield select(state => state.creatorDetails);

  const response = yield getReq(`/creator/${creatorId}?userId=${user?._id || ''}`)
  try {
    if (response.status) {
      yield put({
        type: "GET_CREATOR_DETAILS", payload: response.data
      })
      yield put({
        type: "SET_IS_LOADING", payload: false
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
      yield put({
        type: "SET_IS_LOADING", payload: false
      })
    }
  } catch (e) {

    // yield put({ type: "GET_CREATOR_DETAILS", payload: null });
    yield put({ type: "SET_IS_LOADING", payload: false })
  }

}

function* setAllCollections() {
  const { collectionData, collectionSearch, categoryFilter, chainFilter, timeRangeFilter, offset, sort } = yield select(state => state.allCollections);
  yield put({
    type: "SET_IS_LOADING", payload: true
  })
  const response = yield getReq(`/collections?search=${collectionSearch}&offset=${offset}&sort=${sort}&${categoryFilter !== '' && `filter[categories][0]=${categoryFilter}`}&${chainFilter !== '' && `filter[chains][0]=${chainFilter}`}&${timeRangeFilter !== '' && `filter[timeRange]=${timeRangeFilter}`}`)
  try {
    if (response.status) {
      if (offset === 0) {
        yield put({
          type: "GET_ALL_COLLECTIONS", payload: response.data
        });
      }
      else {
        yield put({
          type: "GET_ALL_COLLECTIONS", payload: [...collectionData, ...response.data]
        });
      }
      yield put({
        type: "SET_IS_LOADING", payload: false
      });
    }
    else {
      yield put({
        type: "SET_IS_LOADING", payload: false
      })
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "GET_ALL_COLLECTIONS", payload: null });
    yield put({
      type: "SET_IS_LOADING", payload: false
    });
  }
}

function* createSellOrder() {
  const { dispatchData: dispatchData } = yield select(state => state.createSellOrder)
  const response = yield postApiReq(`/user/art/${dispatchData.tokenId}/sell`, dispatchData)
  try {
    yield put({
      type: "CREATE_SELL_ORDER", payload: response.status
    })

    if (response.status == false) {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "CREATE_SELL_ORDER", payload: null });
  }
}

function* getCreatorCollection() {

  yield put({ type: "SET_IS_LOADING", payload: true });
  const { creatorData, offset } = yield select(state => state.creatorDetails);

  const response = yield getReq(`/collections/?creatorId=${creatorData?._id}&offset=${offset}`)

  try {
    if (response.status) {
      yield put({
        type: "SET_CREATOR_COLLECTION", payload: response.data
      });
      yield put({ type: "SET_IS_LOADING", payload: false });

    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
      yield put({ type: "SET_IS_LOADING", payload: false });
    }

  } catch (e) {
    yield put({ type: "SET_CREATOR_COLLECTION", payload: null });
    yield put({ type: "SET_IS_LOADING", payload: false });
  }
}
function* getExplorCreator() {
  yield put({ type: "SET_IS_LOADING", payload: true });
  const { search, offset } = yield select(state => state.creatorDetails);
  const response = yield getReq(`/creators?search=${search}&offset=${offset}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_EXPLORE_CREATOR", payload: response.data
      });
      yield put({ type: "SET_IS_LOADING", payload: false });
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
      yield put({ type: "SET_IS_LOADING", payload: false });
    }

  } catch (e) {
    yield put({ type: "SET_CREATOR_COLLECTION", payload: null });
    yield put({ type: "SET_IS_LOADING", payload: false });
  }
}

function* getNFtData() {
  const { nftSearch, offset, filter } = yield select(state => state.allNftReducer);
  const { user } = yield select(state => state.userDetails)
  let str = ``
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      if (value instanceof Array) {
        value.map((item, index) => {
          if (item)
            str = str + `&filter[${key}][${index}]=${item}`
        })
      }
      else {
        for (var price in value) {
          if (value[price])
            str = str + `&filter[${key}][${price}]=${value[price]}`
        }
      }

    }
  }



  const response = yield getReq(`/arts?search=${nftSearch}&offset=${offset}&userId=${user?._id}${str && str}`)
  try {
    if (response.status) {
      yield put({
        type: "SET_ALL_NFT", payload: response.data
      })
    }
    else {
      yield put({
        type: "SET_NOTIF", payload: ({ type: 'error', message: response.error })
      });
    }

  } catch (e) {
    yield put({ type: "SET_ALL_NFT", payload: null });
  }

}

